import apiClient from 'services/axios'
import store from 'store'
// import {
//   store as storeData,
// } from 'index'

// const estateData = storeData.getState().user
// console.log(estateData)

// get estate plus saas data api
export async function estatePlusSaas(param, baseURL) {
  return apiClient
    .post(`${baseURL || ''}/fetch_company_details`, param)
    .then(response => {
      if (response) {
        const { data, status } = response
        if (status) {
          store.set('estatePlus_saas', data.data)
          return response.data
        }
      }
      return false
    })
    .catch(err => console.log(err))
}

// security check in api
export async function securityCheck(payload, baseURL) {
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return apiCall
    .post('/process_request_visits1', payload)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

// security check out api
export async function securityCheckDetails(payload, baseURL) {
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return apiCall
    .post('/display_checkin_lists', payload)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

// login api
export async function login(email, password, baseURL) {
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return apiCall
    .post('/auth/login', {
      email,
      password,
    })
    .then(response => {
      if (response) {
        const { data, status } = response
        if (status) {
          store.set('estate_user', data)
          return response.data
        }
      }
      return false
    })
    .catch(err => console.log(err))
}

// security login api
export async function securityLogin(email, password, baseURL) {
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return apiCall
    .post('/auth/login', {
      email,
      password,
      check: true,
    })
    .then(response => {
      if (response) {
        const { data, status } = response
        if (status) {
          store.set('estate_user', data)
          return response.data
        }
      }
      return false
    })
    .catch(err => console.log(err))
}

// register function
export async function register(email, password, name, baseURL) {
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return apiCall
    .post('/auth/register', {
      email,
      password,
      name,
    })
    .then(response => {
      if (response) {
        const { accessToken } = response.data
        if (accessToken) {
          store.set('accessToken', accessToken)
        }
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

// forgot password
export async function forgotPassword(email, baseURL) {
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return apiCall
    .post('/auth/forgot_password', { email })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

// reset password {from fogot passwor}
export async function resetPassword(data, baseURL) {
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return apiCall
    .post('/auth/reset_password', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

// logout function
export async function logout() {
  store.remove('estate_user')
  store.remove('estatePlus_saas')
}

// dashboard api
export async function dashboard(payload, baseURL) {
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return (
    apiCall
      // .get(`/dashboardDetails`, payload)
      .get(
        payload.page === undefined ? '/dashboardDetails' : `/dashboardDetails?page=${payload.page}`,
      )
      .then(response => {
        if (response) {
          const { accessToken } = response.data
          if (accessToken) {
            store.set('accessToken', accessToken)
          }
          return response.data
        }
        return false
      })
      .catch(err => console.log(err))
  )
}
// export async function dashboard(data) {${payload.id}`, payload
// .post(`/user_complain_response/${payload.id}`, payload)
// const apiCall = baseURL ? apiClient.request({baseURL}) : apiClient
// return apiCall
//     .get(data.page === undefined ? '/dashboardDetails' : `/dashboardDetails?page=${data.page}`)
//     .then(response => {
//       if (response) {
//         const { accessToken } = response.data
//         if (accessToken) {
//           store.set('accessToken', accessToken)
//         }
//         return response.data
//       }
//       return false
//     })
//     .catch(err => console.log(err))
// }

// SINGLE BILL DETAILS
export async function singleBillDetails(id, baseURL) {
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return apiCall
    .get(`/get_billing_details/${id}`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

// function to fetch utility history
export async function utilityHistory(payload, baseURL) {
  // const date = {
  //   from_date: payload && payload[0],
  //   to_date: payload && payload[1],
  // }
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return (
    apiCall
      // .post('/utilityHistory', date)
      .post(
        payload.data === undefined
          ? '/utilityHistory'
          : `/utilityHistory?page=${payload.page || payload.data}`,
        payload.data,
      )
      .then(response => {
        if (response) {
          return response.data
        }
        return false
      })
      .catch(err => console.log(err))
  )
}

// function to fetch export utility history
export async function exportUtilityHistory(payload, baseURL) {
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return apiCall
    .post('/exportUtilityHistory')
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

// function to make utility payment
export async function utilityPayment(data, baseURL) {
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return apiCall
    .post('/utilityPayment', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function vendingRepayment(data, baseURL) {
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return apiCall
    .post('/vending_repayment', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

// function to fetch utility page user building/residence details
export async function utilityBuilding(baseURL) {
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return apiCall
    .get('/userBuildingDetails')
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function vendingDebtDetails(baseURL) {
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return apiCall
    .get('/vending_debt_details')
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function regenerateToken(baseURL) {
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return apiCall
    .get('/regenerate_token')
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

// function to fetch utility page user building/residence details
export async function utilityElectricity(baseURL) {
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return apiCall
    .get('/fetchUnitCalculation')
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

// funtion to fecth utility convinience fee
export async function utilityConvinienceFee(baseURL) {
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return apiCall
    .get('/utilityVendSettings')
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function paybill(payload, baseURL) {
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return apiCall
    .post(`/payBills/${payload.id}`, payload)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function replyComplaints(payload, baseURL) {
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return apiCall
    .post(`/user_complain_response/${payload.id}`, payload)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

// get user profile
export async function profile(baseURL) {
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return apiCall
    .get('/singleProfile')
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

// update password {from porfile update}
export async function updatePassword(data, baseURL) {
  // ('jwt1')
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return apiCall.post('/changePassword', data).then(response => {
    if (response) {
      return response.data
    }
    return false
  })
}

// update user profile
export async function updateProfile(data, baseURL) {
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return apiCall
    .post('/updateProfile', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

// paystack initialize payment
export async function paystackInit(data, baseURL) {
  // console.log(data)
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return apiCall
    .post('/paystack_initialize_payments', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

// flutterwave payment callback
export async function flutterwaveUnit(data, baseURL) {
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return apiCall
    .post('/purchaseElectricityUnits', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

// FETCH ALL DEBIT CARDS BELONGING TO A USER
export async function fetchCards(baseURL) {
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return apiCall
    .get('/fetch_user_card_details')
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

// FETCH ALL PAYMENT METHODS AVAILABLE
export async function fetchPaymentMethods(baseURL) {
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return apiCall
    .get('/fetch_payment_methods')
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

// Add Visitors Request
export async function addVisitors(data, baseURL) {
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return apiCall
    .post('/visitors_request/0', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

// Add other Visitors Request
export async function addOtherVisitors(data, baseURL) {
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return apiCall
    .post('/create_special_visitation', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

// EDIT VISITORS
export async function editVisitors(data, baseURL) {
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return apiCall
    .post(`/visitors_request/${data.id}`, data.data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

// edit other Visitors Request
export async function editOtherVisitors(data, baseURL) {
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return apiCall
    .post(`/update_special_visitation/${data?.id}`, data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

// edit other Visitors Request
export async function invalidateOtherVisitors(data, baseURL) {
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return apiCall
    .post(`/invalidate_visitation/${data?.id}`, data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

// DELETE VISITORS
export async function deleteVisitors(data, baseURL) {
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return apiCall
    .post(`/visitors_request/${data.id}`, data.data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

// DISPLAY COMPLAINTS
export async function displayComplaints(data, baseURL) {
  console.log(data.page)
  // if(data.page === undefined){
  //   const url = '/display_complaint'
  // }
  // else{
  //   const url = `/display_complaint?page=${data.page}`
  // }
  const data1 = {
    search_date: data.payload,
  }
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return apiCall
    .post(
      data.page === undefined ? '/display_complaint' : `/display_complaint?page=${data.page}`,
      data1,
    )
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

// BILLING API'S
// eslint-disable-next-line camelcase
export async function billing(payload, baseURL) {
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return apiCall
    .post(
      payload.page === undefined
        ? '/displayBills'
        : (`/displayBills?page=${payload.page}`, payload),
      payload.data,
    )
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

// COMPLAINT DETAILS
export async function complaintDetails(id, baseURL) {
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return apiCall
    .get(`/complaint_details/${id.id}`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function chatReplies(id, baseURL) {
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return apiCall
    .get(`/user_display_all_chat_replies/${id.id}`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

// export async function markComplaint(data) {
// const apiCall = baseURL ? apiClient.request({baseURL}) : apiClient
// return apiCall
//     .get(`/mark_complaint/${data}`)
//     .then(response => {
//       if (response) {
//         return response.data
//       }
//       return false
//     })
//     .catch(err => console.log(err))
// }

export async function markComplaint(data, baseURL) {
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return apiCall
    .post(`/mark_complaint/${data}`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

// ADD CONPLAINT
export async function addComplaint(data, baseURL) {
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return apiCall
    .post('/add_complains', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

//  DISPLAY ALL BILLING TRANSACTIONS
export async function billTransaction(data, baseURL) {
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return apiCall
    .get(
      data.page === undefined
        ? '/display_all_billing_transactions'
        : `/display_all_billing_transactions?page=${data.page}`,
    )
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function billingDetails(id, baseURL) {
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return apiCall
    .get(`/billing_details_from_mail/${id.id}`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

// get wallet api
export async function wallet(params, baseURL) {
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return apiCall
    .get('/getWalletHistory', { params })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

// Fetch visitors API
export async function fetchVisitors(data) {
  // const data1 = data && data.data
  // console.log(data1)
  const data1 = {
    search_date: data,
  }
  return (
    apiClient
      // .post('/display_visitors_request', data1)
      .post(
        data === undefined
          ? '/display_visitors_request'
          : `/display_visitors_request?page=${data1}`,
        data1,
      )
      // .post(data.page === undefined ? '/display_visitors_request' : (`/display_visitors_request?page=${data.page}`, data))
      .then(response => {
        if (response) {
          return response.data
        }
        return false
      })
      .catch(err => console.log(err))
  )
}

// Export Fetch visitors API
export async function exportFetchVisitors() {
  return apiClient
    .post('/export_daily_visitors_lists')
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

// Fetch Daily VISITORS API
export async function dailyVisitors(data, baseURL) {
  const data1 = data && data.data
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return apiCall
    .post('/daily_visitors_lists', data1)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

// PERMANENT VISITORS API
export async function permanentVisitors(baseURL) {
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return apiCall
    .get('/permanent_visitors_lists')
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

// FUND WALLET
export async function fundWallet(data, baseURL) {
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return apiCall
    .post('/fundWallet', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function transaction(data, baseURL) {
  console.log(data)
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return apiCall
    .post(
      data.page === undefined ? '/allTransactions' : `/allTransactions?page=${data.page}`,
      data.trans,
    )
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function exportTransactions(data, baseURL) {
  console.log(data)
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return apiCall
    .post('/exportAllTransactions')
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function exportWalletTransactions(data, baseURL) {
  console.log(data)
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return apiCall
    .get('/exportWalletHistory')
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

// API to fetch virtual account number
export async function fetchVirtualAccount(data, baseURL) {
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return apiCall
    .get('/fetch_virtual_account', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

// API to fetch bills for export
export async function exportBillings(data, baseURL) {
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return apiCall
    .get('/exportDisplayBills', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

// API to fetch bills transactions for export
export async function exportBillTransactions(data, baseURL) {
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return apiCall
    .get('/export_all_billing_transactions', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

// API to fetch complaint types
export async function fetchComplantTypes(data, baseURL) {
  const apiCall = baseURL ? apiClient.request({ baseURL }) : apiClient
  return apiCall
    .get('/fetch_complain_types', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}
