import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
// import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'

import Layout from 'layouts'

const query = new URLSearchParams(window.location.search) || false
const id = query && query.get('id')
const routes = [
  // public pages
  {
    path: '/',
    // Component: lazy(() => import('pages/auth/login')),
    Component: id ? lazy(() => import('pages/auth/login')) : lazy(() => import('pages/pages/home')),
    exact: true,
  },

  // new public page designs as of early 2023 >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  {
    path: '/home',
    Component: lazy(() => import('pages/pages/home')),
    exact: true,
  },
  {
    path: '/page/residential',
    Component: lazy(() => import('pages/pages/product-resident')),
    exact: true,
  },
  {
    path: '/page/facility',
    Component: lazy(() => import('pages/pages/product-facility')),
    exact: true,
  },
  {
    path: '/page/communication',
    Component: lazy(() => import('pages/pages/product-communication')),
    exact: true,
  },
  {
    path: '/page/payment',
    Component: lazy(() => import('pages/pages/product-payment')),
    exact: true,
  },
  {
    path: '/page/visitor',
    Component: lazy(() => import('pages/pages/product-visitor')),
    exact: true,
  },
  {
    path: '/page/subscription',
    Component: lazy(() => import('pages/pages/subscription')),
    exact: true,
  },
  {
    path: '/page/faqs',
    Component: lazy(() => import('pages/pages/faqs')),
    exact: true,
  },
  {
    path: '/page/contacts',
    Component: lazy(() => import('pages/pages/contact')),
    exact: true,
  },
  {
    path: '/page/policy',
    Component: lazy(() => import('pages/pages/policy')),
    exact: true,
  },
  // ends here >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  // old designs >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  {
    path: '/page/service',
    Component: lazy(() => import('pages/public/ServicePage')),
    exact: true,
  },
  // {
  //   path: '/page/service-single',
  //   Component: lazy(() => import('pages/public/ServiceSinglePage')),
  //   exact: true,
  // },
  // {
  //   path: '/page/faq',
  //   Component: lazy(() => import('pages/public/FaqPage')),
  //   exact: true,
  // },
  // {
  //   path: '/page/contact',
  //   Component: lazy(() => import('pages/public/ContactPage')),
  //   exact: true,
  // },
  // {
  //   path: '/page/404',
  //   Component: lazy(() => import('pages/public/ErrorPage')),
  //   exact: true,
  // },
  {
    path: '/page/sign-in',
    Component: lazy(() => import('pages/public/SignIn')),
    exact: true,
  },
  // ends here >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  // main pages
  {
    path: '/dashboard',
    Component: lazy(() => import('pages/dashboard')),
    exact: true,
  },
  {
    path: '/complaints',
    Component: lazy(() => import('pages/complaints')),
    exact: true,
  },
  {
    path: '/billing',
    Component: lazy(() => import('pages/billings')),
    exact: true,
  },
  {
    path: '/billing/:id',
    Component: lazy(() => import('pages/billings/BillingDetails')),
    exact: true,
  },
  {
    path: '/wallet',
    Component: lazy(() => import('pages/wallet')),
    exact: true,
  },
  {
    path: '/profile',
    Component: lazy(() => import('pages/profile')),
    exact: true,
  },
  {
    path: '/visitors',
    Component: lazy(() => import('pages/visitors')),
    exact: true,
  },
  {
    path: '/vending',
    Component: lazy(() => import('pages/utilities')),
    exact: true,
  },
  {
    path: '/transaction',
    Component: lazy(() => import('pages/transaction')),
    exact: true,
  },
  {
    path: '/elections',
    Component: lazy(() => import('pages/elections')),
    exact: true,
  },
  {
    path: '/contact',
    Component: lazy(() => import('pages/contact')),
    exact: true,
  },
  {
    path: '/whatsnew',
    Component: lazy(() => import('pages/whatsnew')),
    exact: true,
  },
  {
    path: '/security',
    Component: lazy(() => import('pages/security')),
    exact: true,
  },

  // Auth Pages
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/auth/forgot-password',
    Component: lazy(() => import('pages/auth/forgot-password')),
    exact: true,
  },
  {
    path: '/auth/register',
    Component: lazy(() => import('pages/auth/register')),
    exact: true,
  },
  {
    path: '/auth/reset-password',
    Component: lazy(() => import('pages/auth/lockscreen')),
    exact: true,
  },
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/auth/security',
    Component: lazy(() => import('pages/auth/security')),
    exact: true,
  },
]

const mapStateToProps = ({ settings }) => ({
  routerAnimation: settings.routerAnimation,
})

const Router = ({ history, routerAnimation }) => {
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={state => {
            const { location } = state
            return (
              // <SwitchTransition>
              //   <CSSTransition
              //     key={location.pathname}
              //     appear={false}
              //     classNames={routerAnimation}
              //     // timeout={routerAnimation === 'none' ? 0 : 300}
              //   >
              <Switch location={location}>
                {/* <Route exact path="/" render={() => <Redirect to="/home" />} /> */}
                {routes.map(({ path, Component, exact }) => (
                  <Route
                    path={path}
                    key={path}
                    exact={exact}
                    render={() => {
                      return (
                        <div className={routerAnimation}>
                          <Suspense fallback={null}>
                            <Component />
                          </Suspense>
                        </div>
                      )
                    }}
                  />
                ))}
                <Redirect to="/auth/404" />
              </Switch>
              //   </CSSTransition>
              // </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)
